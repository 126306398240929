<template>
  <div class="footer-wrapper">
    <div class="footer">
      <div class="container-fluid w-100">
        <div class="row">
          <div class="col-12 col-md-8">
            <a href="https://www.klopotek.com" target="_blank">
              <img class="customer-brand"
                  src="../assets/img/customers/klopotek-white.png" alt="">
            </a>
            <a href="http://synarchitects.com" target="_blank">
              <img class="customer-brand"
                  src="../assets/img/customers/synarchitects-white.png"
                  style="padding:10px;"
                  alt="">
            </a>
            <a href="https://www.htwk-leipzig.de" target="_blank">
              <img class="customer-brand"
                  src="../assets/img/customers/htwk-white.png"
                  style="padding:15px;"
                  alt="">
            </a>
            <a href="https://www.knips-o-mat.de" target="_blank">
              <img class="customer-brand"
                  src="../assets/img/customers/knipsomat-white.png"
                  style="padding:10px;"
                  alt="">
            </a>
            <a href="https://www.taf-woman.de" target="_blank">
              <img class="customer-brand"
                  src="../assets/img/customers/taf-woman-white.png"
                  style="padding:10px;"
                  alt="">
            </a>
          </div>

          <div class="col-12 col-md-4 text-right">
            <router-link to="/imprint">Imprint</router-link>
            <!--<span> | </span>
            <router-link to="/privacy-policy">Privacy Policy</router-link>
          -->
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'TheFooter',
};
</script>

<style>
.footer-wrapper {
  bottom: 0 !important;
  position: static;

}

.footer {
  min-height: 8vh;
  width: 100%;
  padding: 20px;
  background: linear-gradient(0.25turn, rgba(0,0,0,.6) 10%, rgba(0,0,0,0) 75%);
}

.customer-brand {
  height: 50px;
  padding-right: 15px;
  padding-top: 5px;
}

.customer-brand:hover {
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
}

.rotate {
  transform: rotate(-90deg) !important;

  -webkit-transform: rotate(-90deg);

  /* Firefox */
  -moz-transform: rotate(-90deg);

  /* IE */
  -ms-transform: rotate(-90deg);

  /* Opera */
  -o-transform: rotate(-90deg);

  /* Internet Explorer */
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
}
</style>
