<template>
  <div id="app">
    <!--<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div>-->
    <div id="main-container">
      <div class="container-fluid w-100">
        <div class="row">
          <div class="col-12 col-sm-8 col-md-8 col-lg-6 col-xl-3">
            <div id="profile-card" class="card">
              <div class="card-body">
                <router-view/>
                <TheNavigation />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <TheFooter />

  </div>
</template>

<script>
// @ is an alias to /src

import TheFooter from './components/TheFooter.vue';
import TheNavigation from './components/TheNavigation.vue';

export default {
  name: 'App',
  components: {
    TheFooter,
    TheNavigation,
  },
  data() {
    return {
      bgImage: this.loadBackgroundImage(),
    };
  },
  beforeMount() {
    document.title = 'Benjamin Schädlich | IT-Professional';
    this.loadBackgroundImage();
  },
  methods: {
    loadBackgroundImage: function loadBackgroundImage() {
      const bgImages = [
        'assets/img/pexels-negative-space-34153.jpg',
        'assets/img/pexels-negative-space-34621.jpg',
        'assets/img/pexels-negative-space-134578.jpg',
        'assets/img/pexels-negative-space-134579.jpg',
        'assets/img/pexels-negative-space-160109.jpg',
      ];

      const randomImg = bgImages[Math.floor(Math.random() * bgImages.length)];
      return randomImg;
      /*
      const imgUrl = `url('${randomImg}')`;
      console.log(imgUrl);
      document.body.style.backgroundImage =
       `url('${require('assets/img/pexels-negative-space-34153.jpg')}')`;
      */
    },

  },
};
</script>

<style>
body {
  background-color: #242424 !important; /*#1F1E1F !important; */
  color: white !important;

  background-image: url("assets/img/pexels-negative-space-34153.jpg");
  background-position: top center;
  background-size: cover cover;
  background-repeat: no-repeat;
  font-family: Poppins-Light !important;
}

#main-container {
  top: 0;
  left: 0;
  min-height: 92vh;
  padding: 50px;
}

h1 {
  font-family: Rokkitt;
}

h2 {
  font-family: Rokkitt;
}

a {
  color: #2a9d8f !important;
  font-weight: bold;
}

a:hover {
  color: #9EE5DD !important;
}

.btn-primary {
  background-color: #2a9d8f !important;
}

.btn-primary:hover {
  background-color: #1E7167 !important;
}

.btn-primary:active {
  background-color: #9EE5DD !important;
}

.btn-primary:visited {
  background-color: #9EE5DD !important;
}

</style>
