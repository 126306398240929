<template>
  <div class="home">

    <div class="row p-3">
      <div class="col-12 text-center pb-4">
        <img class="profile-img img-fluid"
          src="../assets/img/pro_profile_image_480x480.jpg">
      </div>

      <div class="col-12 text-center">
        <h1 class="name-shadow">Benjamin <strong>Schädlich</strong></h1>
      </div>
      <div class="col-12 text-center">
        <hr>
        <p>
          <strong>IT-Professional</strong>
           - Network Specialist
           - Automation Enthusiast
           - Passionate Python/JavaScript/PowerShell Developer
        </p>
        <hr>
      </div>

      <div class="col-12 text-center pt-2 pb-2">
        <a target="_blank"
          href="https://www.linkedin.com/in/benjamin-schaedlich-a0900b197/">
          <span class="icon-linkedin social-icon"></span></a>
        <a target="_blank"
          href="mailto:mail@benjamin-schaedlich.de">
          <span class="icon-mail social-icon"></span></a>
        <a target="_blank"
          href="https://www.fiverr.com/easydevandit">
          <span class="icon-calendar social-icon"></span></a>
        <a target="_blank"
         href="https://gitlab.com/benschaedlich">
          <span class="icon-git social-icon"></span></a>
        <a target="_blank"
        href="https://github.com/benschaedlich">
          <span class="icon-github social-icon"></span></a>
        <!--
        <span class="icon-skype social-icon"></span>
        -->
      </div>

    </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
};
</script>

<style>
.home {

}

.profile-img {
  border-radius: 100%;
  border-color: white;
  border-width: 3px !important;
  border-style: solid;
  width: 100px;
  filter: grayscale(20%);
  box-shadow: 7px 8px 35px -8px rgba(0,0,0,0.75);
  -webkit-box-shadow: 7px 8px 35px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 7px 8px 35px -8px rgba(0,0,0,0.75);
}

.social-icon {
  font-size: 2rem !important;
  padding: 5px;
  color: #2a9d8f;

}

.social-icon:hover {
  color: #9EE5DD !important;
}

.name-shadow {
  text-shadow: 4px 4px 5px rgba(0,0,0,0.2);
}

#title-down {
  margin-top:200px !important;
}

/* ------------------------------- */

#profile-card {
  opacity: 0.9;
  box-shadow: 7px 8px 35px -8px rgba(0,0,0,0.75);
  -webkit-box-shadow: 7px 8px 35px -8px rgba(0,0,0,0.75);
  -moz-box-shadow: 7px 8px 35px -8px rgba(0,0,0,0.75);

  color: black;

}
</style>
