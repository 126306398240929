<template>
  <div class="navigation">
    <div class="row">
      <div class="col-12 text-center">
        <div class="center">
          <ul class="nav-items">
            <li><router-link to="/">Home</router-link></li>
            <li> | </li>
            <li><router-link to="/skills">Skills</router-link></li>
            <li> | </li>
            <li><router-link to="/projects">Projects</router-link></li>
            <li> | </li>
            <li><router-link to="/cv">CV</router-link></li>
            <li> | </li>
            <li><a href="https://cloud.benjamin-schaedlich.de" target="_blank">Cloud</a></li>

          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheNavigation',
};
</script>

<style>
.navigation {

}

.center {
  text-align: center;
}

.nav-items {
  display: inline-block;
  text-align: left;
  list-style: none;
  padding: 0;
  font-size: .8rem;
}

.nav-items li {
  float: Left;
  padding-right: 5px;
}

</style>
